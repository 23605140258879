"use client"

import * as React from "react"
import {
  Image,
  Users,
  LayoutDashboardIcon,
  BarChart3,
  Key
} from "lucide-react"

import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarRail,
} from "@/components/ui/sidebar"
import { NavMain } from "./nav-main"
import { NavUser } from "./nav-user"

const data = {
  user: {
    name: "shadcn",
    email: "m@example.com",
    avatar: "/avatars/shadcn.jpg",
  },
  navMain: [
    {
      title: "Dashboard",
      url: "/dashboard",
      icon: LayoutDashboardIcon
    },
    {
      title: "Usage",
      url: "/usage",
      icon: BarChart3
    },
    {
      title: "API Keys",
      url: "/api_keys",
      icon: Key
    },
    // {
    //   title: "Image Analysis",
    //   url: "/image_analysis",
    //   icon: Image
    // },
    {
      title: "Social Analysis",
      url: "/social_analysis",
      icon: Users
    },
  ],
}

export function AppSidebar({ ...props }: React.ComponentProps<typeof Sidebar>) {
  return (
    <Sidebar collapsible="icon" {...props}>
      <SidebarHeader>
        {/* Todo add org switcher */}
      </SidebarHeader>

      <SidebarContent>
        <NavMain items={data.navMain} />
        {/* <NavProperties items={data.navProperties} /> */}
      </SidebarContent>

      <SidebarFooter>
        <NavUser />
      </SidebarFooter>
      <SidebarRail />
    </Sidebar>
  )
}
