import React, { useState } from 'react';
import { useForm } from '@inertiajs/react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Alert, AlertDescription } from '@/components/ui/alert';

export default function ResendConfirmation() {
  const { data, setData, post, processing, errors } = useForm({
    email: '',
  });
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    try {
      await post('/users/confirmation', {
        onSuccess: () => {
          setSuccess(true);
          setData('email', '');
        },
      });
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="flex min-h-screen items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <Card className="w-full max-w-md">
        <CardHeader>
          <CardTitle>Resend confirmation instructions</CardTitle>
          <CardDescription>
            Enter your email address and we'll resend the confirmation instructions.
          </CardDescription>
        </CardHeader>
        <CardContent>
          {success && (
            <Alert className="mb-6">
              <AlertDescription>
                Confirmation instructions sent successfully. Please check your email.
              </AlertDescription>
            </Alert>
          )}
          
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <Input
                id="email"
                type="email"
                value={data.email}
                onChange={e => setData('email', e.target.value)}
                placeholder="Email address"
                required
                className={errors.email ? 'border-red-500' : ''}
              />
              {errors.email && (
                <p className="mt-1 text-sm text-red-600">{errors.email}</p>
              )}
            </div>

            <Button
              type="submit"
              disabled={processing}
              className="w-full"
            >
              {processing ? 'Sending...' : 'Resend confirmation instructions'}
            </Button>
          </form>
        </CardContent>
      </Card>
    </div>
  );
} 