import { useCallback, useState } from "react";
import { Progress } from "@/components/ui/progress";
import { Button } from "@/components/ui/button";
import { DropZone } from "@/components/drop-zone";
import { Loader2, UploadCloud } from "lucide-react";
import { toast } from "sonner";
import { FilePreview } from "./file-preview";
import { useForm } from "@inertiajs/react";

interface FileWithPreview extends File {
  preview?: string;
}

interface FileUploaderProps {
  accept?: string;
  maxFiles?: number;
  className?: string;
}

export function FileUploader({
  accept = "*",
  maxFiles = 10,
  className
}: FileUploaderProps) {
  
  const form = useForm({
    images: [] as File[]
  });

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const newFiles = acceptedFiles.map(file => 
      Object.assign(file, {
        preview: file.type.startsWith('image/') 
          ? URL.createObjectURL(file)
          : undefined
      })
    );

    form.setData('images', [...form.data.images, ...newFiles]);
    toast.success(`Added ${acceptedFiles.length} file${acceptedFiles.length === 1 ? '' : 's'}`);
  }, [form.data.images]);

  const removeFile = (name: string) => {
    const updatedFiles = form.data.images.filter(file => file.name !== name);
    form.setData('images', updatedFiles);
    toast.success(`Removed ${name}`);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (form.data.images.length === 0) {
      toast.error("No files selected");
      return;
    }

    form.post('/image_analysis/upload', {
      onSuccess: () => {
        form.reset();
      },
      onError: () => {
        toast.error("An error occurred while uploading files");
      },
      preserveScroll: true,
      preserveState: true
    });
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <DropZone
        onDrop={onDrop}
        accept={accept}
        maxFiles={maxFiles}
        className={className}
      >
        <div className="flex flex-col items-center justify-center gap-4 p-8">
          <UploadCloud className="h-12 w-12 text-muted-foreground" />
          <div className="space-y-2 text-center">
            <h3 className="text-lg font-semibold">Upload Files</h3>
            <p className="text-sm text-muted-foreground">
              Drag & drop files here, or click to select files
            </p>
            <p className="text-xs text-muted-foreground">
              {accept === "*" ? "Any file type accepted" : `Accepted formats: ${accept}`}
            </p>
            {maxFiles > 1 ? (
              <p className="text-xs text-muted-foreground">
                Up to {maxFiles} files
              </p>
            ) : (
              <p className="text-xs text-muted-foreground">
                Single file only
              </p>
            )}
          </div>
        </div>
      </DropZone>

      {form.data.images.length > 0 && (
        <div className="space-y-4">
          {form.data.images.map(file => (
            <FilePreview
              key={file.name}
              file={file}
              onRemove={removeFile}
            />
          ))}

          {form.progress && (
            <div className="space-y-2">
              <Progress value={form.progress.percentage} className="h-2" />
              <p className="text-sm text-muted-foreground text-center">
                Uploading... {form.progress.percentage}%
              </p>
            </div>
          )}

          <Button
            type="submit"
            className="w-full"
            disabled={form.processing}
          >
            {form.processing ? (
              <>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                Uploading...
              </>
            ) : (
              `Upload ${form.data.images.length} file${form.data.images.length === 1 ? '' : 's'}`
            )}
          </Button>
        </div>
      )}
    </form>
  );
} 