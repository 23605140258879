import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Shield } from "lucide-react";
import { useForm } from "@inertiajs/react";
import { Head, Link } from "@inertiajs/react";
import { PageProps } from "@/types";

interface RegisterForm {
  email: string;
  password: string;
  password_confirmation: string;
}

interface RegisterErrors {
  email?: string[];
  password?: string[];
  password_confirmation?: string[];
}

const Register = ({ auth }: PageProps) => {
  const { data, setData, post, processing, errors } = useForm<{
    user: RegisterForm;
  }>({
    user: {
      email: "",
      password: "",
      password_confirmation: "",
    },
  });

  const formErrors = errors.user as RegisterErrors | undefined;

  function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    post("/register", {
      preserveState: true,
      preserveScroll: true,
    });
  }

  return (
    <>
      <Head title="Register" />
      <Card className="w-full max-w-md">
        <CardHeader className="space-y-1 flex flex-col items-center">
          <div className="flex items-center space-x-2">
            <span className="text-xl font-bold">dScribe AI</span>
          </div>
          <CardTitle className="text-2xl">Create an account</CardTitle>
          <CardDescription>
            Enter your details to create your account
          </CardDescription>
        </CardHeader>
        <CardContent>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="email">Email</Label>
              <Input
                id="email"
                type="email"
                value={data.user.email}
                onChange={(e) => setData("user", { ...data.user, email: e.target.value })}
                required
              />
              {formErrors?.email && (
                <p className="text-sm text-destructive">{formErrors.email}</p>
              )}
            </div>
            <div className="space-y-2">
              <Label htmlFor="password">Password</Label>
              <Input
                id="password"
                type="password"
                value={data.user.password}
                onChange={(e) => setData("user", { ...data.user, password: e.target.value })}
                required
              />
              {formErrors?.password && (
                <p className="text-sm text-destructive">{formErrors.password}</p>
              )}
            </div>
            <div className="space-y-2">
              <Label htmlFor="password_confirmation">Confirm Password</Label>
              <Input
                id="password_confirmation"
                type="password"
                value={data.user.password_confirmation}
                onChange={(e) => setData("user", { ...data.user, password_confirmation: e.target.value })}
                required
              />
              {formErrors?.password_confirmation && (
                <p className="text-sm text-destructive">{formErrors.password_confirmation}</p>
              )}
            </div>
            <Button type="submit" className="w-full" disabled={processing}>
              Sign up
            </Button>
          </form>
          <div className="mt-4 text-center text-sm">
            Already have an account?{" "}
            <Link href="/login" className="text-primary hover:underline">
              Sign in
            </Link>
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export default Register; 