import { usePage } from '@inertiajs/react';
import { toast } from 'sonner';
import { useEffect } from 'react';

interface Flash {
  success?: string;
  error?: string;
  alert?: string;
  notice?: string;
}

interface PageProps {
  flash?: Flash;
}

export function useFlash() {
  const { props } = usePage();
  const flash = props.flash as Flash | undefined;

  useEffect(() => {
    if (flash?.success) {
      toast.success(flash.success);
    }
    if (flash?.error) {
      toast.error(flash.error);
    }
    if (flash?.alert) {
      toast.error(flash.alert);
    }
    if (flash?.notice) {
      toast.message(flash.notice);
    }
  }, [flash]);

  return null;
} 