import React from 'react';
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useForm } from "@inertiajs/react";
import { Head, Link } from "@inertiajs/react";

interface FormData {
  user: {
    email: string;
  };
}

type Errors = {
  user?: {
    email?: string[];
  };
}

export default function ForgotPassword() {
  const { data, setData, post, processing, errors } = useForm<FormData>({
    user: {
      email: '',
    }
  });

  const formErrors = errors.user as Errors['user'];

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    post('/forgot-password');
  };

  return (
    <>
      <Head title="Forgot Password" />
      <Card className="w-full max-w-md">
        <CardHeader className="space-y-1 flex flex-col items-center">
          <div className="flex items-center space-x-2">
            <span className="text-xl font-bold">dScribe AI</span>
          </div>
          <CardTitle className="text-2xl">Forgot your password?</CardTitle>
          <CardDescription>
            Enter your email and we'll send you reset instructions
          </CardDescription>
        </CardHeader>
        <CardContent>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="email">Email</Label>
              <Input
                id="email"
                type="email"
                value={data.user.email}
                onChange={(e) => setData("user", { ...data.user, email: e.target.value })}
                required
              />
              {formErrors?.email && (
                <p className="text-sm text-destructive">{formErrors.email}</p>
              )}
            </div>
            <Button type="submit" className="w-full" disabled={processing}>
              {processing ? "Sending..." : "Send reset instructions"}
            </Button>
          </form>
          <div className="mt-4 text-center text-sm">
            Remember your password?{" "}
            <Link href="/login" className="text-primary hover:underline">
              Back to login
            </Link>
          </div>
        </CardContent>
      </Card>
    </>
  );
} 