import { useState, useEffect } from "react";
import { Head, useForm, Link, usePoll } from "@inertiajs/react";
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { toast } from "sonner";
import { Label } from "@/components/ui/label";
import { Loader2, MessageSquare, Link as LinkIcon, ArrowLeft } from "lucide-react";
import { Badge } from "@/components/ui/badge";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Skeleton } from "@/components/ui/skeleton";

interface Analysis {
  id: number;
  status: string;
  query: string;
  result: any;
  created_at: string;
  time_ago: string;
  url: string | undefined;
  input_type: 'url' | 'social';
  input_data: string;
}

interface Props {
  analysis: Analysis;
  related_analyses: Analysis[];
}

function ResultCard({ title, children }: { title: string; children: React.ReactNode }) {
  return (
    <Card>
      <CardHeader className="pb-3">
        <CardTitle className="text-base">{title}</CardTitle>
      </CardHeader>
      <CardContent>{children}</CardContent>
    </Card>
  );
}

function AnalysisResultSkeleton() {
  return (
    <div className="space-y-4">
      <div className="space-y-4">
        <ResultCard title="Video Summary">
          <div className="space-y-3">
            <Skeleton className="h-4 w-full" />
            <Skeleton className="h-4 w-[90%]" />
            <Skeleton className="h-4 w-[95%]" />
            <Skeleton className="h-4 w-[85%]" />
          </div>
        </ResultCard>

        <ResultCard title="Query Result">
          <div className="space-y-3">
            <Skeleton className="h-4 w-[80%]" />
            <Skeleton className="h-4 w-[70%]" />
          </div>
        </ResultCard>
      </div>
    </div>
  );
}

function AnalysisResult({ result }: { result: any }) {
  const [activeTab, setActiveTab] = useState("summary");
  const data = result?.data?.[0];
  if (!data) return null;

  return (
    <Tabs value={activeTab} onValueChange={setActiveTab} className="w-full">
      <TabsList className="grid w-full grid-cols-2 sm:grid-cols-4">
        <TabsTrigger value="summary">Summary</TabsTrigger>
        <TabsTrigger value="brands">Brands</TabsTrigger>
        <TabsTrigger value="transcript">Transcript</TabsTrigger>
        <TabsTrigger value="raw">Raw Data</TabsTrigger>
      </TabsList>

      <div className="mt-4 space-y-4">
        <TabsContent value="summary" className="m-0">
          <ResultCard title="Video Summary">
            <p className="text-sm text-muted-foreground whitespace-pre-wrap break-words">
              {data.video_summary}
            </p>
          </ResultCard>

          {data.query_result && (
            <ResultCard title="Query Result">
              <p className="text-sm text-muted-foreground break-words">
                {typeof data.query_result.answer === 'string' 
                  ? data.query_result.answer 
                  : JSON.stringify(data.query_result.answer, null, 2)}
              </p>
            </ResultCard>
          )}
        </TabsContent>

        <TabsContent value="brands" className="m-0">
          <ResultCard title="Detected Brands">
            <div className="space-y-4">
              {data.detected_brands?.map((brand: any, index: number) => (
                <div key={index} className="space-y-1">
                  <h4 className="font-medium text-sm">{brand.name}</h4>
                  <p className="text-sm text-muted-foreground break-words">{brand.description}</p>
                </div>
              ))}
            </div>
          </ResultCard>
        </TabsContent>

        <TabsContent value="transcript" className="m-0">
          <ResultCard title="Transcription">
            <ScrollArea className="h-[200px] sm:h-[300px] lg:h-[400px] pr-4">
              <div className="space-y-6">
                {data.paragraphs?.map((paragraph: any, index: number) => (
                  <div key={index} className="space-y-1">
                    {paragraph.sentences.map((sentence: any, sIndex: number) => (
                      <span key={sIndex} className="text-sm break-words">
                        {sentence.text}{" "}
                      </span>
                    ))}
                  </div>
                ))}
              </div>
            </ScrollArea>
          </ResultCard>
        </TabsContent>

        <TabsContent value="raw" className="m-0">
          <ResultCard title="Raw JSON Response">
            <ScrollArea className="h-[200px] sm:h-[300px] lg:h-[400px]">
              <pre className="text-xs whitespace-pre-wrap break-words">
                <code>{JSON.stringify(result, null, 2)}</code>
              </pre>
            </ScrollArea>
          </ResultCard>
        </TabsContent>
      </div>
    </Tabs>
  );
}

function RelatedQueries({ analyses }: { analyses: Analysis[] }) {
  return (
    <div className="grid gap-4 sm:grid-cols-2 lg:grid-cols-1">
      {analyses.map((analysis) => (
        <Link 
          key={analysis.id}
          href={`/social_analysis/${analysis.id}`}
          className="block"
        >
          <Card className="cursor-pointer transition-colors hover:bg-muted/50 h-full">
            <CardContent className="pt-4 sm:pt-6">
              <div className="flex items-start justify-between gap-4">
                <div className="space-y-1 min-w-0 flex-1">
                  <p className="font-medium line-clamp-2">{analysis.query}</p>
                  <p className="text-sm text-muted-foreground">
                    {analysis.time_ago}
                  </p>
                </div>
                {(analysis.status === 'pending' || analysis.status === 'processing') && (
                  <Badge
                    variant="secondary"
                    className="capitalize whitespace-nowrap flex-none animate-pulse"
                  >
                    {analysis.status}
                  </Badge>
                )}
              </div>
              {analysis.status === 'completed' && analysis.result && (
                <div className="mt-4">
                  <p className="text-sm text-muted-foreground line-clamp-3">
                    {typeof analysis.result.data[0].query_result.answer === 'string'
                      ? analysis.result.data[0].query_result.answer
                      : JSON.stringify(analysis.result.data[0].query_result.answer)}
                  </p>
                </div>
              )}
            </CardContent>
          </Card>
        </Link>
      ))}
    </div>
  );
}

function NewQueryForm({ analysis }: { analysis: Analysis }) {
  const { post, processing, data, setData, reset } = useForm(`SocialAnalysis/${analysis.id}`, {
    url: analysis.url || "",
    query: "",
    input_type: analysis.input_type,
    input_data: analysis.input_data,
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    post("/social_analysis", {
      onSuccess: () => {
        toast.success("Analysis started");
        reset("query");
      },
    });
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>Ask Another Question</CardTitle>
        <CardDescription>
          Ask a new question about this {analysis.input_type === 'url' ? 'URL' : 'social profile'}
        </CardDescription>
      </CardHeader>
      <CardContent>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="query">Your Question</Label>
            <Textarea
              id="query"
              placeholder="What would you like to know?"
              value={data.query}
              onChange={(e) => setData("query", e.target.value)}
              required
            />
          </div>
          <div className="flex justify-end">
            <Button type="submit" disabled={processing || !data.query}>
              {processing ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Processing
                </>
              ) : (
                "Ask Question"
              )}
            </Button>
          </div>
        </form>
      </CardContent>
    </Card>
  );
}

function SocialAnalysisShow({ analysis, related_analyses }: Props) {
  const isInProgress = analysis.status === 'pending' || analysis.status === 'processing';
  const { start, stop } = usePoll(2000);

  useEffect(() => {
    if (isInProgress) {
      start();
    } else {
      stop();
    }

    return () => stop();
  }, [isInProgress, start, stop]);

  return (
    <>
      <Head title={`Analysis - ${analysis.query}`} />
      
      <div className="min-h-screen flex flex-col">
        <div className="flex-none px-4 sm:px-6 lg:px-8 py-4 bg-background border-b">
          <div className="container max-w-6xl mx-auto">
            <div className="flex items-start sm:items-center gap-3 sm:gap-4">
              <Button variant="ghost" size="icon" asChild className="mt-1 sm:mt-0">
                <Link href="/social_analysis">
                  <ArrowLeft className="h-4 w-4" />
                </Link>
              </Button>
              <div className="min-w-0 flex-1">
                <h1 className="text-xl sm:text-2xl font-bold tracking-tight truncate">{analysis.query}</h1>
                <div className="flex items-center gap-2 mt-1 text-muted-foreground">
                  {analysis.input_type === 'url' ? (
                    <a 
                      href={analysis.url} 
                      target="_blank" 
                      rel="noopener noreferrer"
                      className="flex items-center gap-1 hover:underline min-w-0 truncate"
                    >
                      <LinkIcon className="h-3 w-3 flex-none" />
                      <span className="truncate">{analysis.url}</span>
                    </a>
                  ) : (
                    <div className="flex items-center gap-1 min-w-0">
                      <MessageSquare className="h-3 w-3 flex-none" />
                      <span className="truncate">{analysis.input_data}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex-1 px-4 sm:px-6 lg:px-8 py-4 sm:py-6">
          <div className="container max-w-6xl mx-auto">
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
              <div className="lg:col-span-2 space-y-6">
                <Card>
                  <CardContent className="pt-4 sm:pt-6">
                    {!isInProgress ? (
                      <AnalysisResult result={analysis.result} />
                    ) : (
                      <div className="space-y-6">
                        <div className="flex items-center justify-between">
                          <div className="space-y-1">
                            <h3 className="text-lg font-medium">Analysis in Progress</h3>
                            <p className="text-sm text-muted-foreground">
                              We're analyzing your request. This usually takes about a minute.
                            </p>
                          </div>
                          <Loader2 className="h-5 w-5 animate-spin text-muted-foreground" />
                        </div>
                        <AnalysisResultSkeleton />
                      </div>
                    )}
                  </CardContent>
                </Card>

                <NewQueryForm analysis={analysis} />
              </div>

              {related_analyses.length > 0 && (
                <div className="space-y-4">
                  <div className="sticky top-0 bg-background py-4 z-10">
                    <h2 className="text-lg font-semibold">Related Questions</h2>
                  </div>
                  <RelatedQueries analyses={related_analyses} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SocialAnalysisShow; 