import { createInertiaApp } from '@inertiajs/react'
import { createRoot } from 'react-dom/client'
import { createElement, ReactNode } from 'react'
import AuthLayout from '@/components/layout/auth'
import GuestLayout from '@/components/layout/guest'

type PageModule = {
  default: React.ComponentType<any> & {
    layout?: typeof AuthLayout | typeof GuestLayout | ((page: ReactNode) => ReactNode)
  }
}

createInertiaApp({
  resolve: async (name) => {
    const pages = import.meta.glob<PageModule>('../pages/**/*.tsx', { eager: true })
    const page = pages[`../pages/${name}.tsx`]
    
    if (!page) {
      throw new Error(`Page not found: ${name}`)
    }

    const PageComponent = page.default
    // Set GuestLayout for auth pages, AuthLayout for other pages
    if (name.startsWith('auth/')) {
      PageComponent.layout = (page: ReactNode) => createElement(GuestLayout, { children: page })
    } else if (!name.startsWith('Public/')) {
      PageComponent.layout = (page: ReactNode) => createElement(AuthLayout, { children: page })
    }
    
    return page
  },
  setup({ el, App, props }) {
    if (el) {
      createRoot(el).render(createElement(App, props))
    }
  },
})
