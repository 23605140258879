import React from 'react';
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Shield } from "lucide-react";
import { useForm } from "@inertiajs/react";
import { Head, Link } from "@inertiajs/react";
import { PageProps } from "@/types";

interface LoginForm {
  email: string;
  password: string;
  remember: boolean;
}

interface LoginErrors {
  email?: string[];
  password?: string[];
  login?: string[];
}

const Login = ({ auth }: PageProps) => {
  const { data, setData, post, processing, errors } = useForm<{
    user: LoginForm;
  }>({
    user: {
      email: "",
      password: "",
      remember: false,
    },
  });

  const formErrors = errors.user as LoginErrors | undefined;
  const [needsConfirmation, setNeedsConfirmation] = React.useState(false);

  function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    setNeedsConfirmation(false);
    
    post("/login", {
      preserveState: true,
      preserveScroll: true,
      onError: (errors) => {
        if (errors?.error === 'You have to confirm your email address before continuing.') {
          setNeedsConfirmation(true);
        }
      }
    });
  }

  return (
    <>
      <Head title="Login" />
      <Card className="w-full max-w-md">
        <CardHeader className="space-y-1 flex flex-col items-center">
          <div className="flex items-center space-x-2">
            <span className="text-xl font-bold">dScribe AI</span>
          </div>
          <CardTitle className="text-2xl">Welcome back</CardTitle>
          <CardDescription>
            Enter your credentials to access your account
          </CardDescription>
        </CardHeader>
        <CardContent>
          {needsConfirmation && (
            <div className="mb-4 p-4 bg-yellow-50 border border-yellow-200 rounded-md">
              <p className="text-sm text-yellow-800">
                You need to confirm your email address before continuing.{" "}
                <Link
                  href="/users/confirmation/new"
                  className="font-medium text-yellow-900 underline hover:text-yellow-800"
                >
                  Resend confirmation instructions
                </Link>
              </p>
            </div>
          )}

          {formErrors?.login && (
            <div className="mb-4 rounded-md bg-red-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <svg className="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                  </svg>
                </div>
                <div className="ml-3">
                  <p className="text-sm font-medium text-red-800">
                    {formErrors.login}
                  </p>
                </div>
              </div>
            </div>
          )}

          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="email">Email</Label>
              <Input
                id="email"
                type="email"
                value={data.user.email}
                onChange={(e) => setData("user", { ...data.user, email: e.target.value })}
                required
              />
              {formErrors?.email && (
                <p className="text-sm text-destructive">{formErrors.email}</p>
              )}
            </div>
            <div className="space-y-2">
              <Label htmlFor="password">Password</Label>
              <Input
                id="password"
                type="password"
                value={data.user.password}
                onChange={(e) => setData("user", { ...data.user, password: e.target.value })}
                required
              />
              {formErrors?.password && (
                <p className="text-sm text-destructive">{formErrors.password}</p>
              )}
            </div>
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  id="remember"
                  checked={data.user.remember}
                  onChange={(e) => setData("user", { ...data.user, remember: e.target.checked })}
                  className="rounded border-gray-300"
                />
                <Label htmlFor="remember">Remember me</Label>
              </div>
              <div className="text-sm">
                <Link href="/forgot-password" className="text-primary hover:underline">
                  Forgot password?
                </Link>
              </div>
            </div>
            <Button type="submit" className="w-full" disabled={processing}>
              {processing ? "Signing in..." : "Sign in"}
            </Button>
          </form>
          <div className="mt-4 text-center text-sm">
            Don't have an account?{" "}
            <Link href="/register" className="text-primary hover:underline">
              Sign up
            </Link>
          </div>
        </CardContent>
      </Card>
    </>
  );
}

export default Login;
