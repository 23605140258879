import React from 'react';
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useForm } from "@inertiajs/react";
import { Head, Link } from "@inertiajs/react";

interface FormData {
  user: {
    password: string;
    password_confirmation: string;
    reset_password_token: string;
  };
}

type Errors = {
  user?: {
    password?: string[];
    password_confirmation?: string[];
    reset_password_token?: string[];
  };
}

interface Props {
  token: string;
}

export default function ResetPassword({ token }: Props) {
  const { data, setData, put, processing, errors } = useForm<FormData>({
    user: {
      password: '',
      password_confirmation: '',
      reset_password_token: token
    }
  });

  const formErrors = errors.user as Errors['user'];

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    put('/reset-password');
  };

  return (
    <>
      <Head title="Reset Password" />
      <Card className="w-full max-w-md">
        <CardHeader className="space-y-1 flex flex-col items-center">
          <div className="flex items-center space-x-2">
            <span className="text-xl font-bold">Scribe Social</span>
          </div>
          <CardTitle className="text-2xl">Reset your password</CardTitle>
          <CardDescription>
            Enter your new password below
          </CardDescription>
        </CardHeader>
        <CardContent>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="password">New Password</Label>
              <Input
                id="password"
                type="password"
                value={data.user.password}
                onChange={(e) => setData("user", { ...data.user, password: e.target.value })}
                required
              />
              {formErrors?.password && (
                <p className="text-sm text-destructive">{formErrors.password}</p>
              )}
            </div>
            <div className="space-y-2">
              <Label htmlFor="password_confirmation">Confirm New Password</Label>
              <Input
                id="password_confirmation"
                type="password"
                value={data.user.password_confirmation}
                onChange={(e) => setData("user", { ...data.user, password_confirmation: e.target.value })}
                required
              />
              {formErrors?.password_confirmation && (
                <p className="text-sm text-destructive">{formErrors.password_confirmation}</p>
              )}
            </div>
            <Button type="submit" className="w-full" disabled={processing}>
              {processing ? "Resetting..." : "Reset Password"}
            </Button>
          </form>
          <div className="mt-4 text-center text-sm">
            Remember your password?{" "}
            <Link href="/login" className="text-primary hover:underline">
              Back to login
            </Link>
          </div>
        </CardContent>
      </Card>
    </>
  );
} 