import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Bar, BarChart, CartesianGrid, XAxis, YAxis, ResponsiveContainer, Legend, Tooltip } from 'recharts';
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
} from "@/components/ui/chart";

interface UsageStats {
  total_seconds: number;
  daily_usage: Record<string, number>;
  successful_requests: number;
  failed_requests: number;
}

interface Props {
  usage_stats: UsageStats;
  monthly_usage: Array<{
    month: string;
    unique_duration_minutes: number;
    total_duration_minutes: number;
    cached_duration_minutes: number;
  }>;
  showCachedUsage?: boolean;
}

interface TooltipProps {
  active?: boolean;
  payload?: Array<{
    value: number;
    color: string;
  }>;
  label?: string;
}

const chartConfig = {
  billable: {
    label: "Billable Usage",
    color: "hsl(var(--chart-1))",
  },
  cached: {
    label: "Cached Usage",
    color: "hsl(var(--chart-2))",
  },
} satisfies ChartConfig;

export default function Usage({ usage_stats, monthly_usage, showCachedUsage = false }: Props) {
  const hasUsageData = usage_stats?.daily_usage && Object.keys(usage_stats.daily_usage).length > 0;
  const hasMonthlyData = monthly_usage && monthly_usage.length > 0;

  // Daily usage data preparation
  const dataMap = new Map(
    Object.entries(usage_stats?.daily_usage || {}).map(([date, seconds]) => [
      date,
      {
        day: date,
        unique_duration_minutes: seconds / 60,
        total_duration_minutes: seconds / 60,
        cached_duration_minutes: 0
      }
    ])
  );

  // Get the date range
  const dates = Array.from(dataMap.keys()).map(date => new Date(date));
  const startDate = dates.length > 0 
    ? new Date(Math.min(...dates.map(d => d.getTime())))
    : new Date(new Date().setDate(new Date().getDate() - 30)); // Default to last 30 days if no data
  const today = new Date();
  today.setUTCHours(23, 59, 59, 999);
  const endDate = dates.length > 0
    ? (today > new Date(Math.max(...dates.map(d => d.getTime()))) ? today : new Date(Math.max(...dates.map(d => d.getTime()))))
    : today;

  // Generate all dates in the range
  const chartData: Array<{
    day: string;
    unique_duration_minutes: number;
    total_duration_minutes: number;
    cached_duration_minutes: number;
  }> = [];

  const currentDate = new Date(startDate);
  while (currentDate <= endDate) {
    const dateString = currentDate.toISOString().split('T')[0];
    const existingData = dataMap.get(dateString);

    chartData.push(
      existingData || {
        day: dateString,
        unique_duration_minutes: 0,
        total_duration_minutes: 0,
        cached_duration_minutes: 0
      }
    );
    currentDate.setDate(currentDate.getDate() + 1);
  }

  // Sort the data by date
  chartData.sort((a, b) => new Date(a.day).getTime() - new Date(b.day).getTime());

  // Update calculations to use seconds from the backend
  const totalUsageMinutes = (usage_stats?.total_seconds || 0) / 60;
  const uniqueUsageMinutes = (usage_stats?.total_seconds || 0) / 60;
  const totalUsageHours = totalUsageMinutes / 60 || 0;
  const uniqueUsageHours = uniqueUsageMinutes / 60 || 0;

  // Monthly usage data preparation
  const sortedMonthlyData = [...(monthly_usage || [])].sort((a, b) => a.month.localeCompare(b.month));
  const latestMonthlyData = sortedMonthlyData.slice(-3);
  const latestDataMonth = latestMonthlyData[latestMonthlyData.length - 1]?.month;

  // Convert minutes to hours for monthly data
  const monthlyDataInHours = latestMonthlyData.map(entry => ({
    ...entry,
    month: entry.month,
    billable_duration_hours: entry.unique_duration_minutes / 60,
    cached_duration_hours: entry.cached_duration_minutes / 60,
    total_duration_hours: entry.total_duration_minutes / 60,
  }));

  const NoDataMessage = () => (
    <div className="flex flex-col items-center justify-center p-12 text-center text-muted-foreground">
      <p className="mb-2">No usage data available yet.</p>
      <p className="text-sm">Data will appear here once you start using the API.</p>
    </div>
  );

  return (
    <div className="container mx-auto p-3 sm:p-6 space-y-4 sm:space-y-6">
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-3 sm:gap-0 mb-4 sm:mb-6">
        <h1 className="text-2xl sm:text-3xl font-bold">Usage</h1>
        <Button asChild>
          <a href="/api_keys">Manage API Keys</a>
        </Button>
      </div>

      {/* Daily Usage Stats */}
      <Card>
        <CardHeader className="flex flex-col items-stretch space-y-0 border-b p-0 lg:flex-row">
          <div className="flex flex-1 flex-col justify-center gap-1 p-4 sm:px-6 sm:py-5 lg:py-6">
            <CardTitle>Daily API Usage</CardTitle>
            <CardDescription className="break-words">
              {hasUsageData 
                ? `Showing daily API usage for ${new Date(chartData[chartData.length - 1].day).toLocaleString('default', { month: 'long', year: 'numeric' })}`
                : "No usage data available"}
            </CardDescription>
          </div>
          <div className="flex flex-wrap border-t lg:border-t-0">
            {showCachedUsage && (
              <div className="relative flex flex-1 flex-col justify-center gap-1 border-b lg:border-b-0 lg:border-l p-4 sm:px-6 sm:py-4 text-left lg:px-8 lg:py-6">
                <span className="text-xs text-muted-foreground">
                  Total Usage
                </span>
                <span className="text-base sm:text-lg font-bold leading-none lg:text-3xl whitespace-nowrap">
                  {totalUsageHours.toFixed(2)} hrs
                </span>
              </div>
            )}
            <div className="relative flex flex-1 flex-col justify-center gap-1 lg:border-l p-4 sm:px-6 sm:py-4 text-left lg:px-8 lg:py-6">
              <span className="text-xs text-muted-foreground">
                {showCachedUsage ? 'Billable Usage' : 'Total Usage'}
              </span>
              <span className="text-base sm:text-lg font-bold leading-none lg:text-3xl whitespace-nowrap">
                {uniqueUsageHours.toFixed(2)} hrs
              </span>
            </div>
          </div>
        </CardHeader>
        <CardContent className="p-2 sm:p-4 lg:p-6">
          {hasUsageData ? (
            <ChartContainer
              config={chartConfig}
              className="aspect-[4/3] sm:aspect-[16/9] h-[250px] sm:h-[300px] w-full"
            >
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  data={chartData}
                  margin={{
                    left: 0,
                    right: 12,
                    top: 12,
                    bottom: 0,
                  }}
                >
                  <CartesianGrid vertical={false} strokeDasharray="3 3" />
                  <XAxis
                    dataKey="day"
                    tickLine={false}
                    axisLine={false}
                    tickMargin={8}
                    minTickGap={32}
                    height={50}
                    tick={{ fontSize: '0.75rem' }}
                    tickFormatter={(value) => {
                      const date = new Date(value + 'T12:00:00');
                      return date.toLocaleDateString("en-US", {
                        month: "short",
                        day: "numeric",
                      });
                    }}
                  />
                  <YAxis
                    tickLine={false}
                    axisLine={false}
                    tickMargin={8}
                    width={60}
                    tick={{ fontSize: '0.75rem' }}
                    tickFormatter={(value) => `${(value / 60).toFixed(1)}`}
                  />
                  <ChartTooltip
                    content={({ active, payload, label }) => {
                      if (active && payload && payload.length) {
                        const billableMinutes = payload[0].value as number || 0;
                        const cachedMinutes = showCachedUsage ? (payload[1]?.value as number || 0) : 0;
                        const totalMinutes = billableMinutes + (showCachedUsage ? cachedMinutes : 0);
                        const date = new Date(label + 'T12:00:00');
                        const formattedDate = date.toLocaleDateString("en-US", {
                          month: "long",
                          day: "numeric",
                          year: "numeric",
                        });
                        
                        return (
                          <div className="custom-tooltip bg-background p-4 rounded-md shadow-md border border-border">
                            <p className="font-semibold mb-2">{formattedDate}</p>
                            <div className="flex flex-col space-y-1">
                              {showCachedUsage && (
                                <div className="flex items-center justify-between">
                                  <span className="flex items-center">
                                    <svg width="12" height="12" className="inline-block mr-2">
                                      <rect width="12" height="6" y="6" fill={chartConfig.billable.color} />
                                      <rect width="12" height="6" fill={chartConfig.cached.color} />
                                    </svg>
                                    Total:
                                  </span>
                                  <span className="ml-4 font-medium">{(totalMinutes / 60).toFixed(2)} hrs</span>
                                </div>
                              )}
                              <div className="flex items-center justify-between">
                                <span className="flex items-center">
                                  <span className="inline-block w-3 h-3 mr-2" style={{ backgroundColor: chartConfig.billable.color }}></span>
                                  {showCachedUsage ? 'Billable:' : 'Total:'}
                                </span>
                                <span className="ml-4 font-medium">{(billableMinutes / 60).toFixed(2)} hrs</span>
                              </div>
                              {showCachedUsage && (
                                <div className="flex items-center justify-between">
                                  <span className="flex items-center">
                                    <span className="inline-block w-3 h-3 mr-2" style={{ backgroundColor: chartConfig.cached.color }}></span>
                                    Cached:
                                  </span>
                                  <span className="ml-4 font-medium">{(cachedMinutes / 60).toFixed(2)} hrs</span>
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      }
                      return null;
                    }}
                  />
                  <Legend />
                  <Bar 
                    dataKey="unique_duration_minutes" 
                    stackId="a"
                    fill={chartConfig.billable.color}
                    name={showCachedUsage ? "Billable" : "Total"}
                  />
                  {showCachedUsage && (
                    <Bar 
                      dataKey="cached_duration_minutes" 
                      stackId="a"
                      fill={chartConfig.cached.color}
                      name="Cached"
                    />
                  )}
                </BarChart>
              </ResponsiveContainer>
            </ChartContainer>
          ) : (
            <NoDataMessage />
          )}
        </CardContent>
      </Card>

      {/* Monthly Usage Stats */}
      <Card>
        <CardHeader className="flex flex-col items-stretch space-y-0 border-b p-0 sm:flex-row">
          <div className="flex flex-1 flex-col justify-center gap-1 p-4 sm:px-6 sm:py-5">
            <CardTitle>Monthly API Usage</CardTitle>
            <CardDescription className="break-words">
              {hasMonthlyData ? (
                <>
                  {new Date(latestMonthlyData[0]?.month).toLocaleString('default', { month: 'long', year: 'numeric' })} - 
                  {new Date(latestDataMonth).toLocaleString('default', { month: 'long', year: 'numeric' })}
                </>
              ) : (
                "No monthly data available"
              )}
            </CardDescription>
          </div>
        </CardHeader>
        <CardContent className="p-2 sm:p-4 lg:p-6">
          {hasMonthlyData ? (
            <div className="aspect-[4/3] sm:aspect-[16/9] h-[300px] sm:h-[400px] w-full">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  data={monthlyDataInHours}
                  margin={{
                    top: 20,
                    right: 20,
                    left: 20,
                    bottom: 20,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis 
                    dataKey="month" 
                    height={50}
                    tick={{ fontSize: '0.75rem' }}
                    tickFormatter={(value) => {
                      const date = new Date(value);
                      return date.toLocaleString('default', { month: 'short' });
                    }}
                  />
                  <YAxis 
                    width={60}
                    tick={{ fontSize: '0.75rem' }}
                    tickFormatter={(value) => `${value.toFixed(1)}`}
                  />
                  <Tooltip 
                    content={({ active, payload, label }) => {
                      if (active && payload && payload.length) {
                        const billableHours = payload[0].value as number;
                        const cachedHours = showCachedUsage ? (payload[1]?.value as number || 0) : 0;
                        const totalHours = billableHours + cachedHours;
                        const date = new Date(label).toLocaleString('default', { month: 'long', year: 'numeric' });
                        
                        return (
                          <div className="custom-tooltip bg-background p-4 rounded-md shadow-md border border-border">
                            <p className="font-semibold mb-2">{date}</p>
                            <div className="flex flex-col space-y-1">
                              {showCachedUsage && (
                                <div className="flex items-center justify-between">
                                  <span className="flex items-center">
                                    <svg width="12" height="12" className="inline-block mr-2">
                                      <rect width="12" height="6" y="6" fill={chartConfig.billable.color} />
                                      <rect width="12" height="6" fill={chartConfig.cached.color} />
                                    </svg>
                                    Total:
                                  </span>
                                  <span className="ml-4 font-medium">{totalHours.toFixed(2)} hrs</span>
                                </div>
                              )}
                              <div className="flex items-center justify-between">
                                <span className="flex items-center">
                                  <span className="inline-block w-3 h-3 mr-2" style={{ backgroundColor: chartConfig.billable.color }}></span>
                                  Billable:
                                </span>
                                <span className="ml-4 font-medium">{billableHours.toFixed(2)} hrs</span>
                              </div>
                              {showCachedUsage && (
                                <div className="flex items-center justify-between">
                                  <span className="flex items-center">
                                    <span className="inline-block w-3 h-3 mr-2" style={{ backgroundColor: chartConfig.cached.color }}></span>
                                    Cached:
                                  </span>
                                  <span className="ml-4 font-medium">{cachedHours.toFixed(2)} hrs</span>
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      }
                      return null;
                    }}
                  />
                  <Legend />
                  <Bar 
                    name="Billable Usage"
                    dataKey="billable_duration_hours" 
                    stackId="a"
                    fill={chartConfig.billable.color}
                    fillOpacity={0.8}
                  />
                  {showCachedUsage && (
                    <Bar 
                      name="Cached Usage"
                      dataKey="cached_duration_hours" 
                      stackId="a"
                      fill={chartConfig.cached.color}
                      fillOpacity={0.8}
                    />
                  )}
                </BarChart>
              </ResponsiveContainer>
            </div>
          ) : (
            <NoDataMessage />
          )}
        </CardContent>
      </Card>
    </div>
  );
} 