import { useState, useEffect } from "react";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Badge } from "@/components/ui/badge";
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from "@/components/ui/card";
import { Loader2, MessageSquare, Link as LinkIcon, Trash2, Globe, Users, Link } from "lucide-react";
import { Head, useForm, usePoll } from "@inertiajs/react";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { toast } from "sonner";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { cn } from "@/lib/utils";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from "@/components/ui/dialog";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Pagy } from "@/types";
import PagyPagination from "@/components/pagy-pagination";

interface Analysis {
  id: number;
  status: string;
  query: string;
  result: any;
  created_at: string;
  time_ago: string;
  url: string | undefined;
  input_type: 'url' | 'social';
  input_data: string;
}

interface Props {
  recent_analyses: Analysis[];
  pagy: Pagy;
  error?: string;
}

function EmptyState() {
  return (
    <div className="flex flex-col items-center justify-center py-12 text-center">
      <MessageSquare className="h-12 w-12 text-muted-foreground/50 mb-4" />
      <h3 className="text-lg font-semibold">No analyses yet</h3>
      <p className="text-sm text-muted-foreground mt-1">
        Start by analyzing some social profiles above.
      </p>
    </div>
  );
}

function usePollAnalyses({ enabled }: { enabled: boolean }) {
  const { start, stop } = usePoll(2000);

  useEffect(() => {
    if (enabled) {
      start();
    } else {
      stop();
    }
  }, [enabled, start, stop]);
}

function AnalysisRow({ analysis }: { analysis: Analysis }) {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showFullJson, setShowFullJson] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const { delete: destroy } = useForm();

  const handleDelete = (e: React.MouseEvent) => {
    e.stopPropagation();
    setShowDeleteDialog(true);
  };

  const confirmDelete = () => {
    destroy(`/social_analysis/${analysis.id}`, {
      onSuccess: () => toast.success("Analysis deleted")
    });
    setShowDeleteDialog(false);
  };

  const renderAnalysisResult = (result: any) => {
    if (!result?.data?.[0]) return null;
    
    const data = result.data[0];
    const [showTranscript, setShowTranscript] = useState(false);

    const renderAnswer = (answer: any) => {
      if (typeof answer === 'string') return answer;
      if (answer?.name && answer?.description) {
        return `${answer.name}: ${answer.description}`;
      }
      return JSON.stringify(answer);
    };

    return (
      <div className="space-y-4">
        {data.query_result && (
          <div className="space-y-1">
            <h4 className="font-medium text-sm">Query Result</h4>
            <div className="text-sm bg-muted/50 p-3 rounded-md">
              <p>{renderAnswer(data.query_result?.answer)}</p>
            </div>
          </div>
        )}

        {data.video_summary && (
          <div className="space-y-1">
            <div className="flex items-center justify-between">
              <h4 className="font-medium text-sm">Summary</h4>
              <Button
                variant="ghost"
                size="sm"
                className="text-xs h-6 px-2"
                onClick={() => setShowSummary(!showSummary)}
              >
                {showSummary ? "Hide" : "Show"}
              </Button>
            </div>
            <div className="text-sm bg-muted/50 p-3 rounded-md">
              {showSummary ? (
                <p className="text-muted-foreground text-xs">{data.video_summary}</p>
              ) : (
                <p className="text-muted-foreground text-xs line-clamp-1">{data.video_summary}</p>
              )}
            </div>
          </div>
        )}

        {data.paragraphs && data.paragraphs.length > 0 && (
          <div className="space-y-1">
            <div className="flex items-center justify-between">
              <h4 className="font-medium text-sm">Transcription</h4>
              <Button
                variant="ghost"
                size="sm"
                className="text-xs h-6 px-2"
                onClick={() => setShowTranscript(!showTranscript)}
              >
                {showTranscript ? "Hide" : "Show"}
              </Button>
            </div>
            <div className="text-sm bg-muted/50 p-3 rounded-md">
              {showTranscript ? (
                <div className="text-muted-foreground text-xs space-y-4">
                  {data.paragraphs.map((paragraph: any, index: number) => (
                    <div key={index} className="space-y-1">
                      {paragraph.sentences.map((sentence: any, sIndex: number) => (
                        <span key={sIndex}>
                          {sentence.text}{" "}
                        </span>
                      ))}
                    </div>
                  ))}
                </div>
              ) : (
                <p className="text-muted-foreground text-xs line-clamp-1">
                  {data.transcription}
                </p>
              )}
            </div>
          </div>
        )}

        {data.detected_brands && data.detected_brands.length > 0 && (
          <div className="space-y-1">
            <h4 className="font-medium text-sm">Detected Brands</h4>
            <div className="flex flex-col gap-2">
              {data.detected_brands.map((brand: { name: string; description: string }, index: number) => (
                <div key={index} className="text-sm bg-muted/50 p-2 rounded-md">
                  <div className="font-medium text-xs">{brand.name}</div>
                  {brand.description && (
                    <div className="text-muted-foreground text-xs mt-1">{brand.description}</div>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}

        <div className="pt-2">
          <Button
            variant="ghost"
            size="sm"
            className="text-xs"
            onClick={() => setShowFullJson(true)}
          >
            View Raw JSON
          </Button>
        </div>
      </div>
    );
  };

  return (
    <>
      <TableRow className="group cursor-pointer hover:bg-muted/50" onClick={() => window.location.href = `/social_analysis/${analysis.id}`}>
        <TableCell className="align-top">
          <div className="font-medium line-clamp-2">{analysis.query}</div>
          <div className="text-sm text-muted-foreground mt-1">
            {analysis.input_type === 'url' && analysis.url ? (
              <a href={analysis.url} target="_blank" rel="noopener noreferrer" className="flex items-center gap-1 hover:underline">
                <LinkIcon className="h-3 w-3" />
                {analysis.url}
              </a>
            ) : (
              <div className="flex items-center gap-1">
                <MessageSquare className="h-3 w-3" />
                {analysis.input_data.split('\n').length} handles
              </div>
            )}
          </div>
        </TableCell>
        <TableCell className="align-top w-[500px]">
          {analysis.result && (
            <div className="w-full">
              {analysis.status === 'completed' ? (
                renderAnalysisResult(analysis.result)
              ) : analysis.status === 'failed' ? (
                <span className="text-destructive">{analysis.result.error}</span>
              ) : null}
            </div>
          )}
        </TableCell>
        <TableCell className="align-top">
          <div className="flex items-center justify-between">
            <Badge
              variant={analysis.status === 'completed' ? 'default' : 'secondary'}
              className={cn(
                "capitalize whitespace-nowrap",
                (analysis.status === 'pending' || analysis.status === 'processing') && "animate-pulse"
              )}
            >
              {analysis.status}
            </Badge>
            <Button
              variant="ghost"
              size="icon"
              className="opacity-0 group-hover:opacity-100 transition-opacity"
              onClick={handleDelete}
            >
              <Trash2 className="h-4 w-4 text-destructive" />
            </Button>
          </div>
        </TableCell>
        <TableCell className="align-top text-muted-foreground">
          {analysis.time_ago}
        </TableCell>
      </TableRow>

      <Dialog open={showDeleteDialog} onOpenChange={setShowDeleteDialog}>
        <DialogContent className="sm:max-w-md">
          <DialogHeader>
            <DialogTitle>Delete Social Analysis</DialogTitle>
            <DialogDescription>
              Are you sure you want to delete this social analysis? This action cannot be undone.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter className="gap-2 sm:gap-0">
            <Button
              variant="outline"
              onClick={(e) => {
                e.stopPropagation();
                setShowDeleteDialog(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="destructive"
              onClick={(e) => {
                e.stopPropagation();
                confirmDelete();
              }}
            >
              Delete
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <Dialog open={showFullJson} onOpenChange={setShowFullJson}>
        <DialogContent className="max-w-4xl h-[80vh]">
          <DialogHeader className="flex-none">
            <DialogTitle>Raw JSON Response</DialogTitle>
          </DialogHeader>
          <div className="flex-1 overflow-y-auto -mx-6 px-6">
            <pre className="text-xs bg-muted/50 p-3 rounded-md">
              <code className="block whitespace-pre">
                {JSON.stringify(analysis.result, null, 2)}
              </code>
            </pre>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

function AnalysisTable({ analyses }: { analyses: Analysis[] }) {
  if (!analyses.length) return <EmptyState />;

  const inProgressAnalyses = analyses.filter(a => a.status === 'pending' || a.status === 'processing');
  const completedAnalyses = analyses.filter(a => a.status === 'completed' || a.status === 'failed');

  const hasPendingAnalyses = inProgressAnalyses.length > 0;
  usePollAnalyses({ enabled: hasPendingAnalyses });

  return (
    <div className="rounded-lg border bg-card">
      <Table>
        <TableHeader>
          <TableRow className="hover:bg-transparent">
            <TableHead className="w-[30%]">Query</TableHead>
            <TableHead className="w-[40%]">Results</TableHead>
            <TableHead className="w-[15%]">Status</TableHead>
            <TableHead className="w-[15%]">Created</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {inProgressAnalyses.length > 0 && (
            <>
              {inProgressAnalyses.map((analysis) => (
                <AnalysisRow key={analysis.id} analysis={analysis} />
              ))}
              <TableRow>
                <TableCell colSpan={4} className="bg-muted/20 py-2 text-center text-sm text-muted-foreground">
                  Completed Analyses
                </TableCell>
              </TableRow>
            </>
          )}
          {completedAnalyses.map((analysis) => (
            <AnalysisRow key={analysis.id} analysis={analysis} />
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

interface NewQueryFormProps {
  error?: string;
}

function NewQueryForm({ error }: NewQueryFormProps) {
  const { post, processing, setData, data } = useForm("NewSocialAnalysis", {
    input_type: "url" as "url" | "social",
    input_data: "",
    query: "",
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    post("/social_analysis", {
      preserveScroll: true,
      onSuccess: () => {
        setData("input_data", "");
        setData("query", ""); 
        toast.success("Analysis started");
      },
    });
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6 pt-4">
      <div className="space-y-6">
        <div className="space-y-2">
            <Tabs 
              defaultValue="url" 
              className="w-full"
              onValueChange={(value) => {
                setData("input_type", value as "url" | "social");
                setData("input_data", "");
              }}
            >
              <TabsList className="grid w-full grid-cols-2">
                <TabsTrigger value="url" className="flex items-center gap-2">
                  <LinkIcon className="h-4 w-4" />
                  URL
                </TabsTrigger>
                <TabsTrigger value="social" className="flex items-center gap-2" disabled={true}>
                  <Users className="h-4 w-4" />
                  Social Handles (coming soon)
                </TabsTrigger>
              </TabsList>
              <TabsContent value="url" className="space-y-2">
                <Input
                  type="url"
                  placeholder="Enter URL to analyze"
                  value={data.input_data}
                  onChange={(e) => setData("input_data", e.target.value)}
                  required={data.input_type === "url"}
                />
              </TabsContent>
              <TabsContent value="social" className="space-y-2">
                <Textarea
                  placeholder="Enter social handles (one per line)"
                  value={data.input_data}
                  onChange={(e) => setData("input_data", e.target.value)}
                  required={data.input_type === "social"}
                  className="min-h-[100px]"
                />
                <p className="text-sm text-muted-foreground">
                  Enter social handles, one per line (e.g., @username)
                </p>
              </TabsContent>
            </Tabs>
          </div>

          <div className="space-y-2">
            <Label htmlFor="query" className="text-base">What would you like to know? (Optional)</Label>
            <Textarea
              id="query"
              placeholder="E.g., What type of products does the creator promote?"
              value={data.query}
              onChange={(e) => setData("query", e.target.value)}
              className="min-h-[100px] resize-none"
            />
            <p className="text-sm text-muted-foreground">
              Leave blank to use default analysis query
            </p>
          </div>
      </div>

      <div className="flex justify-end">
        <Button 
          type="submit" 
          disabled={processing || !data.input_data}
          className="min-w-[120px]"
        >
          {processing ? (
            <>
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              Processing
            </>
          ) : (
            "Analyze"
          )}
        </Button>
      </div>

      {error && (
        <div className="p-3 text-sm text-destructive bg-destructive/10 rounded-md mt-4">
          {error}
        </div>
      )}
    </form>
  );
}

function SocialAnalysis({ recent_analyses = [], error, pagy }: Props) {
  return (
    <>
      <Head title="Social Analysis" />
      <div className="container max-w-6xl mx-auto py-6 px-4 sm:px-6 lg:px-8 space-y-8">
        <div className="flex items-center justify-between">
          <div>
            <h1 className="text-2xl sm:text-3xl font-bold tracking-tight">Social Analysis</h1>
            <p className="text-muted-foreground mt-1">
              Analyze social profiles and discover insights
            </p>
          </div>
        </div>

        <Card>
          <CardContent>
            <NewQueryForm error={error} />
          </CardContent>
        </Card>

        <div className="space-y-4">
          <div className="flex items-center justify-between">
            <h2 className="text-lg font-semibold tracking-tight">Recent Analyses</h2>
          </div>
          <AnalysisTable analyses={recent_analyses} />
          {pagy && pagy.pages > 1 && (
            <div className="mt-4">
              <PagyPagination pagy={pagy} />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default SocialAnalysis; 