"use client"

import { usePage } from "@inertiajs/react"
import { cn } from "@/lib/utils"
import {
  Collapsible,
  CollapsibleTrigger,
} from "@/components/ui/collapsible"
import {
  SidebarGroup,
  SidebarGroupLabel,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
} from "@/components/ui/sidebar"

export function NavMain({
  items,
}: {
  items: {
    title: string
    url: string
    icon?: any
    isActive?: boolean
    items?: {
      title: string
      url: string
    }[]
  }[]
}) {
  const { url: currentPath } = usePage()

  const isActive = (item: { url: string }) => {
    if (item.url === '/') return currentPath === item.url
    return currentPath.startsWith(item.url)
  }

  return (
    <SidebarGroup>
      <SidebarGroupLabel>Platform</SidebarGroupLabel>
      <SidebarMenu>
        {items.map((item) => (
          <Collapsible
            key={item.title}
            asChild
            defaultOpen={item.isActive}
            className="group/collapsible"
          >
            <SidebarMenuItem>
              <CollapsibleTrigger asChild>
                <a 
                  className={cn(
                    "flex items-center",
                    isActive(item) && "bg-accent text-accent-foreground rounded-md"
                  )} 
                  href={item.url}
                >
                  <SidebarMenuButton tooltip={item.title}>
                    {item.icon && <item.icon className="w-5 mr-2" />}
                    <span>{item.title}</span>
                  </SidebarMenuButton>
                </a>
              </CollapsibleTrigger>
            </SidebarMenuItem>
          </Collapsible>
        ))}
      </SidebarMenu>
    </SidebarGroup>
  )
}
