import React, { useState } from 'react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { router } from '@inertiajs/react';
import { Input } from '@/components/ui/input';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from '@/components/ui/dialog';

interface ApiKey {
  id: string;
  name: string;
  key: string;
  created_at: string;
  last_used_at: string | null;
  expires_at: string | null;
  deactivated_at: string | null;
  active: boolean;
}

interface Props {
  api_keys: ApiKey[];
}

const formatDate = (dateString: string | null) => {
  if (!dateString) return 'Never';
  
  const date = new Date(dateString);
  const now = new Date();
  const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);
  
  // If less than a minute ago
  if (diffInSeconds < 60) {
    return 'Just now';
  }
  
  // If less than an hour ago
  if (diffInSeconds < 3600) {
    const minutes = Math.floor(diffInSeconds / 60);
    return `${minutes} minute${minutes === 1 ? '' : 's'} ago`;
  }
  
  // If less than a day ago
  if (diffInSeconds < 86400) {
    const hours = Math.floor(diffInSeconds / 3600);
    return `${hours} hour${hours === 1 ? '' : 's'} ago`;
  }
  
  // If less than a month ago
  if (diffInSeconds < 2592000) {
    const days = Math.floor(diffInSeconds / 86400);
    return `${days} day${days === 1 ? '' : 's'} ago`;
  }
  
  // Otherwise, return the formatted date
  return date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
};

export default function ApiKeys({ api_keys }: Props) {
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [newKeyName, setNewKeyName] = useState('');

  const handleCreate = () => {
    router.post('/usage/api_keys', { 
      name: newKeyName 
    }, {
      onSuccess: () => {
        setIsCreateDialogOpen(false);
        setNewKeyName('');
      }
    });
  };

  const handleDeactivate = (id: string) => {
    if (confirm('Are you sure you want to deactivate this API key?')) {
      router.delete(`/usage/api_keys/${id}/deactivate`, {
        onSuccess: () => {
          router.reload({ only: ['api_keys'] });
        },
      });
    }
  };

  return (
    <div className="container mx-auto p-3 sm:p-6 space-y-4 sm:space-y-6">
      <Card>
        <CardHeader>
          <div className="flex flex-col sm:flex-row justify-between sm:items-center gap-4">
            <div>
              <CardTitle>API Keys</CardTitle>
              <CardDescription>Manage your API keys</CardDescription>
            </div>
            <Button onClick={() => setIsCreateDialogOpen(true)}>
              Create New Key
            </Button>
          </div>
        </CardHeader>
        <CardContent>
          <div className="space-y-3 sm:space-y-4">
            {api_keys.map((key) => (
              <div key={key.id} className="p-3 sm:p-4 border rounded-lg">
                <div className="flex flex-col sm:flex-row sm:justify-between sm:items-start gap-3 sm:gap-4">
                  <div className="flex-1 min-w-0">
                    <div className="flex flex-wrap items-center gap-2">
                      <h4 className="font-medium">{key.name}</h4>
                      {!key.active && (
                        <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
                          Deactivated
                        </span>
                      )}
                    </div>
                    <p className="text-sm text-gray-500 font-mono mt-1 break-all">{key.key}</p>
                    <div className="mt-2 space-y-1">
                      <p className="text-xs text-muted-foreground">
                        Created {formatDate(key.created_at)}
                      </p>
                      {key.last_used_at && (
                        <p className="text-xs text-muted-foreground">
                          Last used {formatDate(key.last_used_at)}
                        </p>
                      )}
                      {key.deactivated_at && (
                        <p className="text-xs text-muted-foreground">
                          Deactivated {formatDate(key.deactivated_at)}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="sm:flex-shrink-0">
                    {key.active && (
                      <Button
                        variant="destructive"
                        size="sm"
                        onClick={() => handleDeactivate(key.id)}
                        className="w-full sm:w-auto"
                      >
                        Deactivate
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </CardContent>
      </Card>

      <Dialog open={isCreateDialogOpen} onOpenChange={setIsCreateDialogOpen}>
        <DialogContent className="sm:max-w-md">
          <DialogHeader>
            <DialogTitle>Create New API Key</DialogTitle>
            <DialogDescription>
              Enter a name for your new API key.
            </DialogDescription>
          </DialogHeader>
          <div className="space-y-4 py-4">
            <div className="space-y-2">
              <Input
                placeholder="Key name"
                value={newKeyName}
                onChange={(e) => setNewKeyName(e.target.value)}
              />
            </div>
            <div className="flex flex-col-reverse sm:flex-row justify-end gap-2 sm:space-x-2">
              <Button variant="outline" onClick={() => setIsCreateDialogOpen(false)} className="w-full sm:w-auto">
                Cancel
              </Button>
              <Button onClick={handleCreate} disabled={!newKeyName.trim()} className="w-full sm:w-auto">
                Create
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
} 