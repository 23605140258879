import * as React from "react"
import { Toaster } from "sonner"
import { useFlash } from "@/hooks/useFlash"

export default function GuestLayout({ children }: { children: React.ReactNode }) {
  useFlash();
  
  return (
    <div className="min-h-screen bg-background">
      <div className="flex min-h-screen flex-col">
        <div className="flex flex-1 flex-col items-center justify-center px-4 py-10">
          {children}
        </div>
        <Toaster />
      </div>
    </div>
  )
} 