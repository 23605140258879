import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
} from "@/components/ui/pagination"
import { Link } from "@inertiajs/react"
import { Pagy } from "@/types"
import { cn } from "@/lib/utils"
import { buttonVariants } from "@/components/ui/button"
import { ChevronLeft, ChevronRight } from "lucide-react"

interface PagyPaginationProps {
  pagy: Pagy
}

type PageNumber = number | '...'

export default function PagyPagination({ pagy }: PagyPaginationProps) {
  const { page, pages, prev_url, next_url, page_url } = pagy
  const isFirstPage = page === 1
  const isLastPage = page === pages

  // Generate an array of page numbers to display
  const getPageNumbers = (): PageNumber[] => {
    const delta = 2 // Number of pages to show before and after current page
    const range: number[] = []
    const rangeWithDots: PageNumber[] = []
    let l: number | undefined

    for (let i = 1; i <= pages; i++) {
      if (i === 1 || i === pages || (i >= page - delta && i <= page + delta)) {
        range.push(i)
      }
    }

    range.forEach((i) => {
      if (l) {
        if (i - l === 2) {
          rangeWithDots.push(l + 1)
        } else if (i - l !== 1) {
          rangeWithDots.push('...')
        }
      }
      rangeWithDots.push(i)
      l = i
    })

    return rangeWithDots
  }

  const pageNumbers = getPageNumbers()

  // Helper function to generate the URL for a specific page
  const getPageUrl = (pageNumber: number) => {
    // Check if page_url contains a query string
    const hasQueryString = page_url.includes('?')
    if (hasQueryString) {
      // Replace the page parameter in the query string
      return page_url.replace(/page=\d+/, `page=${pageNumber}`)
    } else {
      // Add the page parameter as a new query string
      return `${page_url}?page=${pageNumber}`
    }
  }

  return (
    <Pagination>
      <PaginationContent>
        <PaginationItem>
          <Link
            href={prev_url || '#'}
            className={cn(
              buttonVariants({ variant: "ghost", size: "default" }),
              "gap-1 pl-2.5",
              isFirstPage ? "pointer-events-none opacity-50" : ""
            )}
          >
            <ChevronLeft className="h-4 w-4" />
            <span>Previous</span>
          </Link>
        </PaginationItem>

        {pageNumbers.map((pageNumber, index) => (
          <PaginationItem key={index}>
            {pageNumber === '...' ? (
              <PaginationEllipsis />
            ) : (
              <Link
                href={getPageUrl(pageNumber)}
                className={cn(
                  buttonVariants({ 
                    variant: page === pageNumber ? "outline" : "ghost",
                    size: "icon"
                  })
                )}
              >
                {pageNumber}
              </Link>
            )}
          </PaginationItem>
        ))}

        <PaginationItem>
          <Link
            href={next_url || '#'}
            className={cn(
              buttonVariants({ variant: "ghost", size: "default" }),
              "gap-1 pr-2.5",
              isLastPage ? "pointer-events-none opacity-50" : ""
            )}
          >
            <span>Next</span>
            <ChevronRight className="h-4 w-4" />
          </Link>
        </PaginationItem>
      </PaginationContent>
    </Pagination>
  )
} 